import { getPerformanceRuleByPage } from '@/api/performance'
export default [{
  key: 'PerformanceFieldStatus',
  api: () => new Promise((resolve) => {
    resolve(
      {
        data: [{ value: '0', label: '未使用' }, { value: '1', label: '使用中' }]
      }
    )
  }),
  configuration: {
    key: 'value',
    label: 'label',
    value: 'value'
  }
}, {
  key: 'performanceRule',
  api: () => new Promise(async(resolve) => {
    const { datas: { records }} = await getPerformanceRuleByPage({ size: 9999 })
    resolve({ data: records })
  }),
  configuration: {
    key: 'performanceRuleId',
    label: 'performanceRuleName',
    value: 'performanceRuleId'
  }
}]
